export default class news {
  constructor() {
    this.loadButton = document.querySelector('.--load');
    this.xhr = null;
    this.response = {};
    this.articles = document.querySelectorAll('.section__card');
    this.articlesContainer = document.querySelector('.news-wrapper');
    this.hasLoaded = false;
    this.init();
  }

  init = () => {
    this.loadButton.addEventListener('click', this.onLoad, false);
  }

  onLoad = () => {
    if (!this.hasLoaded) {
      this.xhr = new XMLHttpRequest();
      this.xhr.open('GET', '/articles.php', true);
      this.xhr.send();
      this.xhr.addEventListener('readystatechange',
          this.handleResponse.bind(this), false);
    } else {
      this.onResponse();
    }
  }

  handleResponse = (e) => {
    if (this.xhr.readyState == 4 && this.xhr.status == 200) {
      const response = JSON.parse(this.xhr.responseText);
      if (response) {
        this.response = response;
        this.hasLoaded = true;
        this.onResponse();
      }
    }
  }

  onResponse = () => {
    this.articles = document.querySelectorAll('.section__card');
    let count = 0;
    if (this.articles.length < this.response.length) {
      Object.keys(this.response).forEach(key => {
        if (key > this.articles.length - 1 && count < 4) {
          count++;
          let data = this.response[key];
          let schema = this.createSchema(data);
          let fragment = this.renderCardFragment(schema);
          this.articlesContainer.innerHTML += fragment;
        }
      });
    } else {
      this.onFinish();
    }
  }

  createSchema = (data) => {
    let object = {};
    object.title = data.postTitle;
    object.image = data.icon;
    object.summary = this.checkText(data.summary);
    debugger;
    object.url = data.postURL;
    return object;
  }

  checkText = (text) => {
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;
    let newString = '';
    if (wordCount > 30) {
      for (let index = 0; index < words.length; index++) {
        newString += `${words[index]} `;
        if (index >= 29) {
          newString += '...';
          break;
        }
      }

      return newString;
    } else {
      return text;
    }
  }

  renderCardFragment = (data) => {
    return `<div class="section__card js-animate">
    <a href="${data.url}">
      <div class="card__image">
        <img src="${data.image}" alt="${data.title}">
      </div>
      <div class="section__card-wrapper">
        <div class="section__card-content">
          <h2 class="js-animate">${data.title}</h2>
          <div class="js-animate">
            ${data.summary}
          </div>
        </div>
        <div class="link__wrapper">
          <button class="section__button js-animate" >
            <span>Read more</span>
            <i class="arrow"></i>
          </button>
        </div>
      </div>
    </a>
  </div>`;
  }

  onFinish = () => {
    this.loadButton.remove();
    const h2 = document.createElement('h2');
    h2.classList.add('finished');
    h2.innerText = 'End';
    const parentEl = this.articlesContainer.parentElement.parentElement;
    parentEl.appendChild(h2);
  }
}
